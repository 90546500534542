/**
 * BishopGuanare 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 18/04/2020
 */

import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import logo from '../img/obispoGuanare.jpg';
import escudoObispo from '../img/Escudo-VII-Obispo-Diocesis-Guanare.jpg';
import {Helmet} from "react-helmet";
import { Trans } from '@lingui/macro';

export default class BishopGuanare extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("bishop-diocese-of-guanare.html") ? "en" : "es");
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;      
        if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);    
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
    }

    componentDidMount() {
        if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') || (this.state.lang!==null && this.state.lang==='en')) {            
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
               this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') ? sessionStorage.getItem('language') : this.state.lang);  
            }
        }        
    }

    invokeChangeLanguage(language) {
        if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
            this.setState({ lang: language });         
            sessionStorage.setItem('language', language);        
            this.state.eventChangeLanguage(language);            
        }
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        let helmetComp;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
                <title>VII Obispo de la Di&#243;cesis de Guanare Monse&#241;or Owaldo Enrique Araque Valero</title>
                <meta name="description" content="Obispo de la Di&#243;cesis de Guanare Monse&#241;or Owaldo Enrique Araque Valero" />
                <meta name="keywords" content="obispo, Diócesis, guanare, Monseñor, Owaldo, Araque" />
            </Helmet>;
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>VII Bishop of the Diocese of Guanare Monsignor Owaldo Enrique Araque Valero</title>
                <meta name="description" content="Bishop of the Diocese of Guanare Monsignor Owaldo Enrique Araque Valero" />
                <meta name="keywords" content="Bishop, Diocese, Guanare, Monsignor, Owaldo, Araque" />
            </Helmet>;
        }

        return (<div>
             {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />     
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                    <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3><Trans>BishopGuanarePageLb</Trans></h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12" align="center">
                                        <img src={logo} className="img-thumbnail" alt="Monseñor Owaldo Araque"/>
                                    </div>                                   
                                </div>
                                <div className="row">
                                    <div className="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <p><Trans>BishopGuanareP1Lb</Trans></p>                                                         
                                            <p><Trans>BishopGuanareP2Lb</Trans></p>
                                            <p><Trans>BishopGuanareP3Lb</Trans></p>
                                            <p><Trans>BishopGuanareP4Lb</Trans></p>                                                                        
                                        </article>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12" align="center">
                                        <img src={escudoObispo} className="img-thumbnail" alt='Escudo Obispo Diocesis de Guanare'/>
                                    </div>                                   
                                </div>
                                <div className="row">
                                    <div className="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <p><Trans>BishopGuanareP5Lb</Trans></p>                                                                                                                                                             
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>
            </div>
            <Footer />
        </div>);
    }

}